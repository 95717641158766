import {
  Container,
  CssBaseline,
  Paper,
  ThemeProvider,
} from '@material-ui/core';

import React, { useContext ,useEffect} from 'react';
import { Store } from './Store';
import { Route, BrowserRouter } from 'react-router-dom';
import ChooseScreen from './screens/ChooseScreen';
import OrderScreen from './screens/OrderScreen';
import QueueScreen from './screens/QueueScreen';
import HomeScreen from './screens/HomeScreen';
import { createTheme } from '@material-ui/core/styles';
import ReviewScreen from './screens/ReviewScreen';
import PaymentScreen from './screens/PaymentScreen';
import CompleteOrderScreen from './screens/CompleteOrderScreen';

import { Helmet } from 'react-helmet';
import './App.css';
import config,{getParameterByName,merchantCode} from './Configs'

import {getUserData,getUserSettings} from './actions';


const theme = createTheme({
  typography: {
    h1: { fontWeight: 'bold' },
    h2: {
      fontSize: '2rem',
      color: 'black',
    },
    h3: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
      color: 'black',

    },
  },
  palette: {
    primary: { main: '#ff1744' },
    secondary: {
      main: '#118e16',
      contrastText: '#ffffff',
    },
  },
});
function App() {
  const { state, dispatch } = useContext(Store);
  useEffect(()=>{getUserData(dispatch);},[])         
  const isScan = getParameterByName("isScan");
  return (
    <BrowserRouter>
      <Helmet>
        <title>Self-Order Kiosk</title>
      </Helmet>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth={state.widthScreen ? 'xl' : 'sm'}>
          <Paper>
           {isScan==="true"? <Route path="/" component={ChooseScreen} exact></Route>
           : <Route path="/" component={HomeScreen} exact></Route>
           } 
            <Route path="/queue" component={QueueScreen} exact></Route>
            <Route path="/choose" component={ChooseScreen} exact></Route>
            <Route path="/order" component={OrderScreen} exact></Route>
            <Route path="/complete" component={CompleteOrderScreen} exact></Route>

          </Paper>
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
}
export default App;
