import React, { useEffect, useState,useRef,useContext } from "react";
import axios from "axios";
import { Store } from "../Store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import html2canvas from "html2canvas";
import moment from "moment";
import config,{getParameterByName} from "../Configs";
import CurrencySymbol from '../components/CurrencySymbol';
import { useIntl } from "react-intl";

function BillPrint(props) {
  const { formatMessage: t, locale, setLocale } = useIntl();
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(Store);
  const [print, setPrint] = useState(true);
  const { taxPercent, dineinTax,takeAwayTax } = state;
  const orderId =props.orderDetails.orderId ;
  const currency =  props.orderDetails.currency; 
  const address = props.orderDetails.address; 
  const cgst = props.orderDetails.cgst;
  const invoiceNo = props.orderDetails.invoice_no || props.invoiceNo;
  const merchantCode =  props.orderDetails.merchantCode;
  const restaurant = props.orderDetails.restuarant;
  const baseURL = config.baseURL;
  const billRef = useRef(null);
  const orderDetails = props.orderDetails;
  let taxPerc = props.orderDetails.orderType.toLowerCase()=='eat in'? (taxPercent || dineinTax): (taxPercent || takeAwayTax);
    const isScan = getParameterByName("isScan");

 const printReceipt = () => {
       try{
      localStorage.setItem('isPrintCall', "Y");
      let printDetails= {};
      printDetails.tokenNo= 'Token #'+orderDetails.number;
      printDetails.merchant=orderDetails.restaurant;
      printDetails.invoiceNo=t({ id: "invoice" })+'#:'+invoiceNo;
      printDetails.date=  moment(orderDetails.createdAt).format("DD-MM-YYYY h:mm a");
      printDetails.header1=t({ id: "items" });
      printDetails.header2=t({ id: "quantity" });
      printDetails.header3=t({ id: "amount" });
      printDetails.orderItems=[];
      //printDetails.currencyIcon=<CurrencySymbol/>;
      printDetails.currency=currency;
      printDetails.address=address;
      printDetails.subTotal=t({ id: "amount" })+' '+currency+' '+(orderDetails.totalPrice - orderDetails.taxPrice).toFixed(2);
      printDetails.tax=t({ id: "tax" })+ '('+taxPerc+'%): '+currency+' '+orderDetails.taxPrice.toFixed(2);
      printDetails.total=t({ id: "total" })+": "+currency+' '+orderDetails.totalPrice.toFixed(2);

      let AddorderItems=orderDetails && orderDetails.orderItems&& 
                       orderDetails.orderItems.map((item, index) => {
                          const subProArray =
                            item.sub_pro && JSON.parse(item.sub_pro);
                            item.price=item.price +
                                  (subProArray && subProArray.length
                                    ? subProArray.reduce(
                                        (acc, val) => acc + val.price,
                                        0
                                      )
                                    : 0)
                              printDetails.orderItems.push(item)
                              });

      let bill= document.getElementById("bill");
      console.log(printDetails);
      if (bill) {
        //html2canvas(bill).then((canvas) => {
        //  const canvasUrl = canvas.toDataURL("image/png");
         // const base64Image = canvasUrl.split(",")[1];
         // console.log(base64Image);
          if(window.PrintInterface){
             window.PrintInterface.print(JSON.stringify(printDetails));
          }else{
          window.chrome.webview.postMessage(printDetails);
          }
          //localStorage.setItem('isPrintCall', "N");
          //props.setBillPrint(false);
      }
    }catch(e){
      console.log("print error",e);
    }
  
}

  const handleBack = () => {
    let isMerchant = sessionStorage.getItem("isMerchant");
    sessionStorage.setItem("billing", false);
    console.log(isMerchant);
    if (isMerchant) {
      window.location.href = `/epos?merchantCode=${merchantCode}`;
    } else {
      window.location.href = "/epos";
    }
  };
  // const [addressPart, gstPart, fssaiPart] = address.split(/GST:|FSSAI:/);
  // console.log(addressPart, gstPart, fssaiPart);
console.log(orderDetails)
  return (
    <div style={{ zIndex: "1",width:"80%",textAlign:"center" }}> 
        {!isScan && <button
                      className="pay-btn"
                      onClick={()=>printReceipt()}
                      style={{padding:"2px 10px",height:"35px",marginTop:"20px"}}
                    >
                      {t({ id: "take_print" })}
                    </button>}
           <h6
                    style={{
                      fontWeight: "bold",
                      padding: "1px",
                      fontSize: "20px",
                      margin:"10px",
                      color:"#0a7205"
                    }}
                  >
                    {t({ id: "take_snap" })}
                  </h6>      
        <div id="bill" className="receipt" style={{paddingTop:"30px",background:"#82fdae"}}>
        

        <hr style={{ border: "1px solid black",margin:'5px' }} />
          {orderDetails ? (
            <div
              className="container"
              style={{
                textAlign: "center"
              }}
            >
              <div id="orderDetails" style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center",margin:"5px",lineHeight:'1'}}>
                  Token #{orderDetails.number}
                </h2>
                {false&&<div style={{textAlign:'center'}}>
                  <div id="status_chip">{t({ id: "preparing" })}</div>
                </div>}
                {false && <p
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "bold",
                    margin:'2px'
                  }}
                >
                 {t({ id: "order_summary" })}
                </p>}
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight:"bold",
                    lineHeight:'1'
                  }}
                >
                 {orderDetails.restaurant}
                </div>

                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "12px",
                    fontWeight:"bold",
                    lineHeight:'1'
                  }}
                >
                  <p>{t({ id: "invoice" })}#: {invoiceNo}</p>
                  <p>
                    {moment(orderDetails.createdAt).format("DD-MM-YYYY h:mm a")}
                  </p>
                </div>
                <hr style={{ border: "1px solid black",margin:'5px' }} />
                <table
                  style={{ fontSize: "13px",fontWeight:"bold"}}
                  align="center"
                  height="auto"
                  width="100%"
                  
                >
                  <thead align="center">
                    <tr style={{borderBottom:'2px dotted #000'}}>
                      <th align="start">{t({ id: "items" })}</th>
                      <th>{t({ id: "quantity" })}</th>
                      <th>{t({ id: "amount" })}</th>
                    </tr>
          
                  </thead>
                  <tbody align="center">
                    {orderDetails && orderDetails.orderItems 
                      ? orderDetails.orderItems.map((item, index) => {
                          const subProArray =
                            item.sub_pro && JSON.parse(item.sub_pro);
                          return (
                            <tr key={index} style={{borderBottom:'1px dotted #000'}}>
                              <td align="start">{item.name}</td>
                              <td>{item.quantity}</td>
                              <td>
                              <CurrencySymbol/>{" "}
                                {item.price +
                                  (subProArray && subProArray.length
                                    ? subProArray.reduce(
                                        (acc, val) => acc + val.price,
                                        0
                                      )
                                    : 0)}
                              </td>
                              <td>
                                {item &&
                                item.status &&
                                item.status.toUpperCase() === "READY" ? (
                                  <input
                                    type="checkbox"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      accentColor: "#08eb0875",
                                    }}
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                    <tr>
                      <td colSpan="4">
                        <hr style={{ border: "1px solid black" }}></hr>
                      </td>
                    </tr>
                    {false &&<tr>
                      <td colSpan="2" align="end">
                        <b>{t({ id: "amount" })} :</b>
                      </td>
                      <td align="right">
                        {}{" "}
                        {(orderDetails.totalPrice - orderDetails.taxPrice).toFixed(2)}
                      </td>
                    </tr>}
                    <tr>
                      <td colSpan="2" align="end">
                        <b>{t({ id: "tax" })} ({taxPerc}%):</b>
                      </td>
                      <td align="right">
                         <CurrencySymbol/> {" "}{orderDetails.taxPrice.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                    </tr>
                  </tbody>
                </table>
                <p
                  align="center"
                  style={{
                    margin: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  id="tot"
                >
                  {t({ id: "total" })}:  <span  style={{
                    margin: "5px",
                    fontSize: "2.0em",
                    fontWeight: "bold",
                  }}><CurrencySymbol/>{" "}
                  { orderDetails.totalPrice.toFixed(2) }</span>
                    
                </p>
              </div>
              {false &&<p style={{ textAlign: "center",fontSize:"11px",fontWeight:"bold" }}>
                {t({ id: "thanks_note" })} <br /> {t({ id: "thanks_note_1" })}
              </p>}
              {false &&<p
                style={{
                  textAlign: "left",
                  fontSize:"11px"
                }}
              >
                <i>{t({ id: "poweredby" })}</i> <b>MenuLive</b>
              </p>}
              
            </div>
          ):""}
        </div>
        
        
        </div>


  );
}

export default BillPrint;
