import React from 'react';
import { useStyles } from '../styles';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

export default function CatButton(props) {
  //const styles = useStyles();
  console.log(props.categories);
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl);
 const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    !anchorEl?setAnchorEl(event.currentTarget): setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const styles = useStyles();
  return (
     <div className="cat_dox" onClick={handleClick}  aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        <div>
        <RestaurantMenuIcon sx={{width:"20px",padding:"0px", cursor: "pointer", color: "white" }} />
         <span style={{fontSize:"1em",color:"#fff"}}>
          {"Menu"}
        </span>
        </div>
         <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{top:"10px!important"}}
      >
        {
          props.categories.filter(cat=> cat.isOrderableAlone || !cat.isAddOn).map(cat=>{return <MenuItem onClick={()=>(props.categoryClickHandler(cat),handleClose())}> <ListItemIcon>
            <img
                    className={styles.image +" "+ 'cat-img'}
                    alt={cat.name}
                    onError={props.imageOnErrorHandler}
                    src={cat.image}
                    style={{width:"50px",height:"50px",marginRight:"20px"}}

                  />
          </ListItemIcon><Typography variant="body2" sx={{ color: '#fff' }}>{cat.name}      </Typography></MenuItem>})
        }
       
      </Menu>
       
      </div>
  );
}
